import { useInterval } from "@chakra-ui/react";
import { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { logsApi } from "../api/requestFactory";
import useRequest from "../api/useRequest";

export const LogsContext = createContext();

export const LogsContextComponent = (props) => {
  const { children } = props
  const { guildId } = useParams()
  const { requestMaker } = useRequest();
  const [logs, setLogs] = useState(undefined)
  const [hasUnreadLogs, setHasUnreadLogs] = useState(false)

  async function getLogs() {
    try {
      if (!guildId) return
      const resp = await requestMaker(logsApi.get(guildId))
      setLogs(resp.data)
      const unviewedLogs = resp.data.filter(log => log.has_viewed === false)
      if (unviewedLogs.length > 0) {
        setHasUnreadLogs(true)
      } else {
        setHasUnreadLogs(false)
      }
    } catch (err) {
      console.error(err);
    }
  }

  useInterval(() => {
    getLogs()
  }, 8000);

  useEffect(() => {
    if (!guildId) return
    setLogs(undefined)
    getLogs()
  }, [guildId]);

  return (
    <LogsContext.Provider value={{ logs, getLogs, hasUnreadLogs }}>
      {children}
    </LogsContext.Provider>
  )
}