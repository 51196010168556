import Crown from '../components/Crown';
import Rocket from '../components/Rocket';

export const isGuildOnPaidPlan = subscription_plan => {
  return (
    subscription_plan === 'PREMIUM_PLAN' ||
    subscription_plan === 'BASE_PLAN' ||
    subscription_plan === 'PLUS_PLAN'
  );
};

export const canMakeMoreCustomFlows = (subscription_plan, flows) => {
  if(!subscription_plan || !flows) return false;

  const numberOfActiveCustomFlows = flows.filter(o => o.isEnabled && !o.id.includes("flow_simple")).length;

  if (subscription_plan === 'PREMIUM_PLAN' || subscription_plan === 'PLUS_PLAN') {
    return true;
  }
  
  if (subscription_plan === 'BASE_PLAN') {
    return numberOfActiveCustomFlows < 10;
  }

  return numberOfActiveCustomFlows < 2;
}

export const canGuildHaveCustomBot = subscription_plan => {
  return (
    subscription_plan === 'PREMIUM_PLAN' || subscription_plan === 'PLUS_PLAN'
  );
};

export const isGuildPlus = subscription_plan => {
  return subscription_plan === 'PLUS_PLAN';
};

export const getPlanIcon = subscription_plan => {
  return props => {
    switch (subscription_plan) {
      case 'PREMIUM_PLAN':
        return <Crown {...props} />;
      case 'BASE_PLAN':
        return <Crown {...props} />;
      case 'PLUS_PLAN':
        return <Rocket {...props} />;
      default:
        return null;
    }
  };
};
