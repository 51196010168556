import { Box, HStack, Text, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'
import { BsCaretRightFill } from 'react-icons/bs'
import { Link as RouterLink } from 'react-router-dom';


export const NavItem = (props) => {
  const { active, startElement, children, label, endElement, href, externalLink } = props
  const bgColor = useColorModeValue('gray.100', 'gray.700')

  const Item = () => {
    return (
      <HStack
        w="full"
        px={props.px || "3"}
        py={props.py || "2"}
        m={props.m || undefined}
        cursor="pointer"
        userSelect="none"
        rounded="md"
        transition="all 0.2s"
        //color={active ? 'blurple' : undefined}
        bg={active ? bgColor : undefined}
        _hover={{ bg: bgColor }}
        _active={{ bg: bgColor }}
      >
        <Box fontSize="2xl" color={active ? 'blurple' : undefined}>
          {startElement}
        </Box>
        <Box flex="1" fontWeight="inherit" >
          <Text >{label}</Text>
        </Box>
        {endElement && !children && <Box>{endElement}</Box>}
        {children && <Box fontSize="xs" flexShrink={0} as={BsCaretRightFill} />}
      </HStack>
    )
  }

  function getComponent() {
    if (externalLink) {
      return (
        <Box onClick={() => { window.open(externalLink) }}>
          <Item />
        </Box>
      )
    } else {
      return (
        <RouterLink to={href ? href : '/dashboard'}>
          <Item />
        </RouterLink>
      )
    }
  }

  return getComponent()
}
