import { useContext, useEffect } from 'react';
import { UserContext } from '../context/UserContextComponent';
import logrocket from 'logrocket';

const LogRocketIdentify = props => {
  const { children } = props;
  const { userData } = useContext(UserContext);

  useEffect(() => {
    if (!userData) return;

    logrocket.identify(userData.id, {
      name: `${userData.first_name} ${userData.last_name}`,
      email: userData.email,
    });
  }, [userData]);

  return <>{children}</>;
};

export default LogRocketIdentify;
