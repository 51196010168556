import axios from 'axios';
import { getConfig } from '../config';
import { BadRequest, GeneralError, NotFound, ServerError, NetworkError } from './errors/Errors';
const { apiOrigin } = getConfig();

const baseURL = apiOrigin;

const makeRequest = async (request) => {
  return axios({
    baseURL,
    ...request,
  })
    .catch(handleApiErrors)
    .then((response) => response.data);
};


export const handleApiErrors = (err) => {
  console.error(err);
  if (!err.response) {
    throw new NetworkError()
  }
  const internalError = err.response.data?.data?.error
  const statusCode = err.response.status

  switch (statusCode) {
    // These errors will happen when the request is bad
    // These are our custom errors that are defined in the backend.
    // Custom errors should have a display_error field and 
    // sometimes an internal_error_code field like 40103 which means "User Not Found"
    case 400:
      throw new BadRequest({ code: statusCode, error: internalError });
    case 404:
      throw new NotFound({ code: statusCode, error: internalError });

    // These errors will happen if the server is down 
    // or something that needs to be fixed on the server
    // typically just 500
    case 500:
    case 501:
    case 502:
    case 503:
    case 504:
      throw new ServerError();

    // General errors should never be returned, this is a catch all
    default:
      throw new GeneralError();
  }
};

export default makeRequest;