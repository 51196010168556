import { Box, HStack, Image, Tag, Text } from "@chakra-ui/react"

const BotDiscoLabel = () => {
  return (
    <HStack
      px="3"
      py="2"
      spacing="3">
      <Box display='inline-flex' position='relative'>
        <Image
          src={`https://cdn.discordapp.com/app-icons/952326251123728505/bd347a40a3b5dc80f646e4e702481a82.jpg`}
          alt="icon"
          width="30"
          height="30"
          borderRadius={'50%'}
          border='2px'
        />
      </Box>
      <Box textAlign="start">
        <HStack>
          <Text fontSize={'x-large'}>BotDisco</Text>
          <Tag size={'sm'} bg='blurple' color='white'>BOT</Tag>
        </HStack>
      </Box>
    </HStack>
  )

}

export default BotDiscoLabel