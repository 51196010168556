import {
  Center,
  HStack,
  Menu,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spinner,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import { AccountSwitcherButton } from './AccountSwitcherButton'
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from "../../context/UserContextComponent";
import { useContext } from "react";
import BotLabel from './BotLabel';

export const AccountSwitcher = (props) => {
  const { managedGuilds } = useContext(UserContext);
  const { guildId } = useParams()
  const navigate = useNavigate()

  const guildList = () => {
    if (!managedGuilds) {
      return <Center><Spinner size={'sm'} /></Center>
    }
    return (
      <>
        {managedGuilds?.length > 1 &&
          <>
            <MenuOptionGroup
              defaultValue={guildId}
            >
              {managedGuilds?.map((guild) => {
                return (
                  <MenuItemOption
                    iconSpacing={0}
                    value={guild.id}
                    key={guild.id}
                    fontWeight="semibold"
                    rounded="md"
                    onClick={() => { navigate(`/dashboard/${guild.id}/builder`) }}
                  >
                    <HStack flex="1" spacing="3" pl={2}>
                      <Text>{guild.name}</Text>
                    </HStack>
                  </MenuItemOption>
                )
              })}
            </MenuOptionGroup>
            <MenuDivider />
          </>}
      </>
    )
  }
  return (
    <Menu >
      <BotLabel />
      <AccountSwitcherButton />
      <MenuList
        shadow="lg"
        py="4"
        color={useColorModeValue('gray.600', 'gray.200')}
        px="3"
        maxH={'50vh'}
        overflow='auto'
      >
        {guildList()}
        <MenuItem
          rounded="md"
          _hover={{ 'bg': useColorModeValue('green.100', 'green.700') }}
          onClick={() => { navigate(`/dashboard`) }}
        >
          Add a server
        </MenuItem>
      </MenuList>
    </Menu >
  )
}
