import { useIntercom } from "react-use-intercom";
import { Fragment, useContext, useEffect } from "react";
import { UserContext } from "../context/UserContextComponent";
import { GuildContext } from "../context/GuildContextComponent";

const Intercom = (props) => {
  const { children } = props
  const { boot, shutdown, hide, show, update } = useIntercom();
  const { userData } = useContext(UserContext);
  const { guildData } = useContext(GuildContext)

  boot({
    name: `${userData.first_name} ${userData.last_name}`,
    email: userData.email,
  })

  useEffect(() => {
    const debugUrl = `https://dash.botdisco.com/dashboard/${guildData?.disco_guild?.id}/builder/`
    update({
      name: `${userData.first_name} ${userData.last_name}`,
      email: userData.email,
      customAttributes: { subscription_plan: guildData?.disco_guild?.subscription_plan, "Dashboard URL": debugUrl },
    })

  }, [guildData]);



  return (
    <Fragment>
      {children}
    </Fragment>
  )
}

export default Intercom