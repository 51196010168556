import { Box, Circle, Icon, Tooltip } from "@chakra-ui/react"
import { BiCrown } from "react-icons/bi"
import { FaCrown } from "react-icons/fa"

const Crown = (props) => {
  const { enabled, size, left, top } = props
  return (
    <Tooltip
      label={'This server is on a paid premium plan! ✌️'}
      placement='top-start'
      shouldWrapChildren
    >
      <Icon
        as={FaCrown}
        position={'absolute'}
        boxSize={size || '8px'}
        left={left}
        top={top}
        color='gold'
        zIndex='99'
      />
    </Tooltip>
  )
}

export default Crown