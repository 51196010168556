import { Circle, Icon } from "@chakra-ui/react"

const StatusCircle = (props) => {
  const { enabled, size } = props
  return (
    <Icon
      as={Circle}
      position={'absolute'}
      boxSize={size || '8px'}
      right='0px'
      bottom='0px'
      bg={enabled ? "green.400" : "red.400"}
    />
  )
}

export default StatusCircle