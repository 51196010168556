import {
  Box, Flex, useColorModeValue as mode,
  useBreakpointValue,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  Center,
} from '@chakra-ui/react'
import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';
import { GuildContextComponent } from '../../context/GuildContextComponent';
import { LogsContext, LogsContextComponent } from '../../context/LogsContextComponent';
import LogRocketIdentify from '../LogRocket';
import Intercom from '../Intercom';
import DashboardSidebar from './DashboardSidebar'
import { ToggleButton } from './ToggleButton';

const DynamicHelmet = () => {
  const { hasUnreadLogs } = useContext(LogsContext)

  return (
    <Helmet>
      <title> {hasUnreadLogs ? `(1)` : ''} BotDisco | Dashboard</title>
    </Helmet>
  )
}

export const DashboardLayout = (props) => {
  const { children, showSidebarBuilder } = props
  const boxColor = mode('gray.100', 'gray.800')
  const isDesktop = useBreakpointValue({ base: false, lg: true })
  const { isOpen, onToggle, onClose } = useDisclosure()

  const SideDrawer = ({ children }) => {
    return (
      <Box
        w="10"
        bg={boxColor}
        fontSize="sm"
        borderRight='1px'
        borderColor='gray.300'
        onClick={onToggle}
        cursor='pointer'
      >
        <Center h='100%'>
          <ToggleButton isOpen={isOpen} aria-label="Open Menu" onClick={onToggle} />
        </Center>
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          isFullHeight
        >
          <DrawerOverlay />
          <DrawerContent maxWidth='64' >
            {children}
          </DrawerContent>
        </Drawer>
      </Box>
    )
  }

  return (
    <GuildContextComponent doFetchGuild={showSidebarBuilder}>
      <LogsContextComponent>
        <Intercom>
          <LogRocketIdentify>
            <DynamicHelmet />
            <Box height="100vh" overflow="hidden" position="relative" >
              <Flex h="full" id="app-container" >
                {isDesktop ?
                  <DashboardSidebar showSidebarBuilder={showSidebarBuilder} />
                  :
                  <SideDrawer><DashboardSidebar showSidebarBuilder={showSidebarBuilder} /></SideDrawer>
                }
                <Box bg={boxColor} flex="1" overflowX={'auto'} >
                  {children || <Outlet />}
                </Box>
              </Flex>
            </Box>
          </LogRocketIdentify>
        </Intercom>
      </LogsContextComponent>
    </GuildContextComponent>
  )
}
