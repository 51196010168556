import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import { Helmet } from 'react-helmet-async';
import theme from './theme';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import logrocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { install } from 'ga-gtag';
import { ANALYTICS_ID } from './utils/analytics';


logrocket.init('mrycj6/botdisco');
setupLogRocketReact(logrocket);

const INTERCOM_APP_ID = 'e7px7arf';

function App() {
  if (typeof window !== 'undefined') {
    install(ANALYTICS_ID);
  }
  const content = useRoutes(routes);
  return (
    <>
      <Helmet>
        <title>BotDisco</title>
      </Helmet>
      <ChakraProvider theme={theme}>
        <IntercomProvider appId={INTERCOM_APP_ID}>{content}</IntercomProvider>
      </ChakraProvider>
    </>
  );
}

export default App;
