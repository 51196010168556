import { gtag } from 'ga-gtag';

export const ANALYTICS_ID = 'G-CYFM50PH98';
export const DESTINATION_ID = 'AW-10845876849';
export const SIGNUP_CONVERSION_ID = 'xG_8CK217owYEPHk27Mo';

export const TWITTER_CONVERSION_ID = 'odyej';
export const TWITTER_SIGNUP_EVENT_ID = 'tw-odyej-oe3ew';

export const report_conversion = async (callback_fn) => {
  // if (process.env.NEXT_PUBLIC_ENV !== 'prod' || process.env.NODE_ENV !== 'production') {
  //   callback_fn && callback_fn();
  //   return;
  // }
  gtag('event', 'conversion', {
    'send_to': `${DESTINATION_ID}/${SIGNUP_CONVERSION_ID}`
  });
  if (window.twq) {
    await window.twq('event', TWITTER_SIGNUP_EVENT_ID, {});
  }
  callback_fn && callback_fn();
};