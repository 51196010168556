import { Avatar, Box, HStack, Image, Tag, Text } from "@chakra-ui/react"
import { useContext } from "react"
import { GuildContext } from "../../context/GuildContextComponent"
import StatusCircle from "../StatusCircle"

const BotLabel = () => {
  const { guildData } = useContext(GuildContext)

  return (
    <HStack
      px="2"
      pb="8"
      spacing="6">
      <Box minW={'30px'}>
        <Box display='inline-flex' position='relative'>
          <Avatar
            src={`https://cdn.discordapp.com/app-icons/${guildData.disco_guild.bot.app_id}/${guildData.disco_guild.bot.icon}.jpg`}
            name={guildData.disco_guild.bot?.name}
            bg='blurple'
          />
          <StatusCircle enabled={guildData.disco_guild.bot.is_enabled} size={'12px'} />
        </Box>
      </Box>
      <Box textAlign="start">
        <HStack>
          <Text fontSize={'large'} fontWeight={'bold'}>{guildData.disco_guild.bot?.name}</Text>
          <Tag size={'sm'} bg='blurple' color='white'>BOT</Tag>
        </HStack>
      </Box>
    </HStack>
  )

}

export default BotLabel