import { Box, Flex, HStack, useMenuButton, useColorModeValue, Spinner, Image, Avatar, Icon, Text, Tooltip } from '@chakra-ui/react'
import * as React from 'react'
import { FaCrown, FaRocket } from 'react-icons/fa';
import { HiSelector } from 'react-icons/hi'
import { UserContext } from '../../context/UserContextComponent';
import { getPlanIcon, isGuildOnPaidPlan } from '../../utils/guild';
import Crown from '../Crown';

export const AccountSwitcherButton = (props) => {
  const { currentGuild } = React.useContext(UserContext);
  const buttonProps = useMenuButton()

  const PlanIcon = getPlanIcon(currentGuild?.subscription_plan)

  const CurrentGuild = () => {
    if (!currentGuild) {
      return <Spinner />
    }
    return (
      <>
        <Box display='inline-flex' position='relative'>
          <Avatar
            height={'36px'}
            width={'36px'}
            src={`https://cdn.discordapp.com/icons/${currentGuild.id}/${currentGuild.icon}.jpg`}
            name={currentGuild.name}
            bg={'blurple'}
            border='2px'
          />
        </Box>
        <Box textAlign="start">
          <Box isTruncated fontWeight="semibold">
            <HStack>
              {isGuildOnPaidPlan(currentGuild.subscription_plan) && 
                  <Icon
                    as={currentGuild.subscription_plan === "PREMIUM_PLAN" || currentGuild.subscription_plan === "BASE_PLAN" ? FaCrown : FaRocket}
                    boxSize={'24px'}
                    color='gold'
                    zIndex='99'
                  />
              }
              <Text
                whiteSpace={'nowrap'}
                overflow={'hidden'}
                textOverflow={'ellipsis'}>
                {currentGuild.name}
              </Text>
            </HStack>
          </Box>
        </Box>
      </>
    )
  }

  return (
    <Flex
      as="button"
      {...buttonProps}
      w="full"
      display="flex"
      alignItems="center"
      rounded="lg"
      bg={useColorModeValue("gray.200", "gray.700")}
      px="3"
      py="2"
      fontSize="sm"
      userSelect="none"
      cursor="pointer"
      outline="0"
      transition="all 0.2s"
      _active={{ bg: useColorModeValue("gray.200", "gray.600") }}
      _focus={{ shadow: 'outline' }}
    >
      <HStack
        flex="1"
        spacing="3"
        whiteSpace={'nowrap'}
        overflow={'hidden'}
        textOverflow={'ellipsis'}
      >
        {CurrentGuild()}
      </HStack>
      <Box fontSize="lg" color="gray.400">
        <HiSelector />
      </Box>
    </Flex>
  )
}
