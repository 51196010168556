import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fieldsApi, userGuildApi, qnaApi } from "../api/requestFactory";
import useRequest from "../api/useRequest";
import { UserContext } from "./UserContextComponent";
import useDidMountEffect from "../hooks/useDidMountEffect";
import LoadingBox from "../components/LoadingBox";

// const getCachedGuild = (userData, guildId) => {
//   return userData?.bots?.find(bot => bot?.bot?.id === guildId)
// }

export const GuildContext = createContext();

export const GuildContextComponent = (props) => {
  const { children, doFetchGuild } = props
  const { userData } = useContext(UserContext)
  const { guildId } = useParams()
  const { requestMaker } = useRequest();
  const [guildData, setGuildData] = useState() //getCachedGuild(userData, guildId)
  const [isFetching, setIsFetching] = useState(false)
  const [numberOfActiveFlows, setNumberOfActiveFlows] = useState(0)
  const [qnaBotOnboarded, setQnaBotOnboarded] = useState(false);
  const [channels, setChannels] = useState(undefined)
  const [members, setMembers] = useState(undefined)
  const [qna, setQna] = useState()


  // TODO do I need to update the userData with the new guildData?
  const refreshGuildData = async () => {
    setIsFetching(true)
    try {
      const response = await requestMaker(userGuildApi.get(guildId))
      setIsFetching(false)
      const newGuildData = response.data
      console.log(response.data)
      const channelList = newGuildData.channels
      const members = newGuildData.members
      const numActiveFlows = newGuildData.disco_guild.botData.data?.flows?.filter((flow) => flow.isEnabled).length;
      const qnaOnboarded = newGuildData.disco_guild.has_qna_onboarded;
      setNumberOfActiveFlows(numActiveFlows)
      setGuildData(newGuildData)
      setQnaBotOnboarded(qnaOnboarded)
      setChannels(channelList)
      setMembers(members)
    } catch (err) {
      setIsFetching(false)
      console.error(err);
    }
  }

  const refreshMemberFieldData = async () => {
    setIsFetching(true)
    try {
      const response = await requestMaker(fieldsApi.list(guildId))
      setIsFetching(false)
      const newMemberFields = response.data
      const newGuildData = guildData
      newGuildData.disco_guild.member_fields = newMemberFields

      setGuildData({ ...newGuildData })
      console.log('refreshed guild data');
    } catch (err) {
      setIsFetching(false)
      console.error(err);
    }
  }

  const refreshQnaData = async () => {
    setIsFetching(true)
    try {
      const response = await requestMaker(qnaApi.get(guildId))
      setIsFetching(false)
      const newQnaData = response.data
      setQna(newQnaData)
      console.log(response.data)
    } catch (err) {
      setIsFetching(false)
      console.error(err);
    }
  }

  // https://stackoverflow.com/a/56443045
  useEffect(() => {
    if (!guildData && !isFetching && doFetchGuild) {
      refreshGuildData()
    }
  }, [guildData, userData]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (guildId && (!qna && !isFetching)) {
      refreshQnaData()
    }
  }, [qna]);

  // ran if 'guildId' changes, but not on initial render
  useDidMountEffect(() => {
    if (guildId && doFetchGuild) {
      refreshGuildData()
    }
  }, [guildId]);

  if (!guildData && doFetchGuild) {
    return <LoadingBox />
  }

  return (
    <GuildContext.Provider value={{ guildData, members, channels, numberOfActiveFlows, qnaBotOnboarded, qna, refreshGuildData, refreshMemberFieldData, refreshQnaData }}>
      {children}
    </GuildContext.Provider>
  )
}